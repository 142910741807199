<template>
  <div>
    <b-card border-variant="danger" v-if="errore_caricamento">
      <b-row class="match-height">
        <b-col
          md="8"
        >
          <h4 class="mt-0 mb-0 text-danger">Errore durante il caricamento delle informazioni iniziali</h4>
          <p>Ti preghiamo di segnalare il problema all'assistenza tecnica, grazie</p>
        </b-col>
        <b-col 
          md="4"
          class="text-center"
        >
          <b-button
          variant="primary"
          :to="{ name: 'crm-leads-index' }"
          >
              <feather-icon
                  icon="CornerUpLeftIcon"
                  size="16"
                  class="mr-1"
              />Torna ai Leads
          </b-button>
        </b-col>
      </b-row>
        
    </b-card>
    
    <div class="text-center my-3" v-if="Caricato == false">
      <b-spinner
        variant="primary"
        style="width: 3rem; height: 3rem;"
        class="mr-1"
        label="loading"
      />
    </div>

    <div v-if="Caricato" class="position-relative">
        <div class="mio_btn_float">
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon rounded-circle"
              id="`sidebar-azioni`"
              @click="sidebarActionShow(campiform.lead.id, campiform.lead.name+' '+campiform.lead.surname, campiform.billing.vat_number, campiform.lead.email, controllo_duplicato_show, campiform.lead.note)"
          >
              <feather-icon icon="ArrowRightIcon" />
          </b-button>
        </div>
        <b-card>
            <b-card-body class="px-0 py-0">
              <b-row v-if="controllo_duplicato_show">
                <b-col class="mb-3" v-if="controllo_duplicato.stato_lead != 'Ok'">
                  <div>
                    <b-media no-body>
                      <b-media-aside>
                        <b-avatar
                          rounded
                          size="42"
                          variant="light-danger"
                        >
                          <feather-icon
                            size="18"
                            icon="AlertTriangleIcon"
                          />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body>
                        <h6 class="transaction-title">
                          Controllo stato Lead
                        </h6>
                        <small>{{ this.controllo_duplicato.stato_lead }}</small>
                      </b-media-body>
                    </b-media>
                  </div>
                </b-col>
                <b-col class="mb-3" v-if="controllo_duplicato.controllo_lead != 'Ok'">
                  <b-media no-body>
                    <b-media-aside>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-danger gradient-primary"
                        v-b-tooltip.hover
                        title="Non è possibile convertire questo Lead perchè l'email principale appartiene ad un cliente. Inserisci un'email differente oppure gestisci direttamente il cliente"
                        @click.prevent="goTO(controllo_duplicato.controllo_lead_id_lead)"
                      >
                        <feather-icon
                          size="18"
                          icon="AlertTriangleIcon"
                        />
                      </b-button>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Controllo Duplicati Lead
                      </h6>
                      <small>{{ this.controllo_duplicato.controllo_lead }}</small>
                    </b-media-body>
                  </b-media>

                </b-col>
                <b-col class="mb-3" v-if="controllo_duplicato.controllo_clienti != 'Ok'">
                  <b-media no-body>
                    <div v-if="controllo_duplicato.controllo_clienti_deleted_at">
                      qui funzione per ripristinare il cliente dal softdelete di laravel e poi rimandare alla scheda del cliente ! (e forzo che id_stato sia soft_delete)
                    </div>
                    <div v-else>
                      <b-media-aside>
                        <b-button
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          variant="outline-danger gradient-primary"
                          v-b-tooltip.hover
                          title="Non è possibile convertire questo Lead perchè l'email principale appartiene ad un cliente. Inserisci un'email differente oppure gestisci direttamente il cliente"
                          @click="$router.push({ name: 'crm-customers-show', params: { id_riga: controllo_duplicato.controllo_clienti_id_cliente }})"
                        >
                          <feather-icon
                            size="18"
                            icon="AlertTriangleIcon"
                          />
                        </b-button>
                      </b-media-aside>
                    </div>
                    <b-media-body>
                      <h6 class="transaction-title">
                        Controllo Duplicati Clienti
                      </h6>
                      <small>{{ this.controllo_duplicato.controllo_clienti }}</small>
                    </b-media-body>
                  </b-media>

                </b-col>
              </b-row>

                <b-tabs pills>
                    <b-tab active>
                    <template #title>
                        <feather-icon
                        icon="UserIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Anagrafica</span>
                    </template>
                    <b-card bg-variant="Default">
                        <b-row>
                          <b-col md="4" lg="3">
                            <showdata field_name="Ragione Sociale" :field_value="campiform.billing.company_name" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Nome" :field_value="campiform.lead.name" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Cognome" :field_value="campiform.lead.surname" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Email" :field_value="campiform.lead.email" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Telefono" :field_value="campiform.lead.phone" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Cellulare" :field_value="campiform.lead.mobile_phone" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Reseller" :field_value="campiform.lead.reseller_name" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Assistente" :field_value="campiform.lead.assistant_name" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Sito web" :field_value="campiform.lead.website" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <dl>
                              <dt class="text-muted">Stato</dt>
                              <dd>
                                <b-badge :variant="statusVariant(campiform.lead.status.value)">
                                  {{ campiform.lead.status.text }}
                                </b-badge>
                              </dd>
                            </dl>

                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Valutazione" :field_value="campiform.lead.evalution_name" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Rating" :field_value="campiform.lead.rating" />
                          </b-col>
                        </b-row>

                    </b-card>
                    </b-tab>

                    <b-tab>
                    <template #title>
                        <feather-icon
                        icon="BriefcaseIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Fatturazione</span>
                    </template>
                    <b-card bg-variant="Default">
                        <b-row v-if="campiform.lead.has_billing === true">
                          <b-col md="4" lg="3">
                            <showdata field_name="Indirizzo" :field_value="campiform.billing.address" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Cap" :field_value="campiform.billing.zip" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Città" :field_value="campiform.billing.city" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <div v-if="campiform.billing.province_name == 'Estera'">
                              <showdata field_name="Provincia" :field_value="campiform.billing.province_foreign" />
                            </div>
                            <div v-else>
                              <showdata field_name="Provincia" :field_value="campiform.billing.province_name" />
                            </div>
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Nazione" :field_value="campiform.billing.country_name" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Partita IVA" :field_value="campiform.billing.vat_number" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Codice fiscale" :field_value="campiform.billing.fiscal_code" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Pec" :field_value="campiform.billing.pec" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Codice SDI" :field_value="campiform.billing.dest_code" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Regime IVA" :field_value="campiform.billing.vat_scheme_name" />
                          </b-col>
                          <b-col md="4" lg="6">
                            <showdata field_name="Pagamenti Accettati" :field_value="formattazioneArray(campiform.lead.payment)" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Split Payment" :field_value="campiform.billing.split_payment" />
                          </b-col>
                          <b-col md="8" lg="6">
                            <showdata field_name="Note XML" :field_value="campiform.billing.note_xml" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Rappresentante legale" :field_value="campiform.billing.legal_representative" />
                          </b-col>
                        </b-row>
                        
                        <b-row v-if="campiform.openapi.scaricato === true">
                          <b-col md="4" lg="3">
                            <showdata field_name="Stato attività" :field_value="campiform.openapi.base.stato_attivita" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Rea" :field_value="campiform.openapi.base.rea" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Camera di commercio" :field_value="campiform.openapi.base.cciaa" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Codice ateco" :field_value="campiform.openapi.base.codice_ateco" />
                          </b-col>
                          <b-col md="8" lg="9">
                            <showdata field_name="Descrizione codice ateco" :field_value="campiform.openapi.base.descrizione_ateco" />
                          </b-col>
                          <b-col md="4" lg="3">
                            <showdata field_name="Forma giuridica" :field_value="campiform.openapi.base.codice_natura_giuridica" />
                          </b-col>
                        </b-row>
                    </b-card>
                    </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="LockIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Bilancio</span>
                    </template>
                    <b-card bg-variant="Default">
                        <div v-if="campiform.openapi.scaricato === true">
                          <b-table responsive="sm" :items="campiform.openapi.bilanci"/>
                        </div>
                        <div v-else>
                          <b-card border-variant="primary">
                            <b-card-text>Bilanci non ancora presenti, è possibile scaricarli con la relativa funzione del menu laterale</b-card-text>
                          </b-card>
                        </div>
                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                        <feather-icon
                        icon="LinkIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                        />
                        <span class="d-none d-sm-inline">Tracks</span>
                    </template>
                    <b-card bg-variant="Default" v-if="campiform.lead.privacy">
                      <b-row>
                        <b-col md="4" lg="4">
                          <showdata field_name="Registrazione" :field_value="formattazioneDataOra(campiform.lead.created_at, 'it')" />
                        </b-col>
                        <b-col md="4" lg="4">
                          <showdata field_name="Origine" :field_value="campiform.lead.source.text" />
                        </b-col>
                        <b-col md="4" lg="4">
                          <dl>
                            <dt class="text-muted">Sezione</dt>
                            <dd class="capitalize-first-word">{{ campiform.lead.section }}</dd>
                          </dl>
                        </b-col>
                        <b-col md="12" lg="12">
                          <showdata field_name="Path" :field_value="campiform.lead.path" />
                        </b-col>
                        <b-col md="4" lg="4">
                          <showdata field_name="Accettazione termini e condizioni lead" field_value="Si" />
                        </b-col>
                        <b-col md="4" lg="4">
                          <showdata field_name="Accettazione informativa privacy lead" field_value="Si" />
                        </b-col>
                        <b-col md="4" lg="4">
                          <showdata field_name="Iscritto newsletter" :field_value="campiform.lead.newsletter" />
                        </b-col>
                        <b-col md="6" lg="6">
                          <strong class="text-muted">Logs e firma digitale</strong>
                          <div class="logs-colonna">
                            {{ formattazioneDataOra(campiform.lead.created_at, 'it') }} - Accettazione privacy<br />
                            {{ formattazioneDataOra(campiform.lead.created_at, 'it') }} - <span v-if="campiform.lead.termini">Accettazione termini</span><span v-else>Termini non accettati</span><br />
                            <span v-if="campiform.lead.user_agent != ''">{{ formattazioneDataOra(campiform.lead.created_at, 'it') }} - {{ campiform.lead.user_agent }}</span><br />
                            <span v-if="campiform.lead.ip != ''">{{ formattazioneDataOra(campiform.lead.created_at, 'it') }} - {{ campiform.lead.ip }}</span>
                          </div>
                        </b-col>

                      </b-row>
                    </b-card>
                    <b-card v-else>
                      <b-row>
                        <b-col md="4" lg="4">
                          <showdata field_name="Registrazione" :field_value="formattazioneDataOra(campiform.lead.created_at, 'it')" />
                        </b-col>
                        <b-col md="4" lg="4">
                          <showdata field_name="Origine" :field_value="campiform.lead.source.text" />
                        </b-col>
                        <b-col md="4" lg="4">
                          <showdata field_name="Privacy Policy e Termini e Condizioni accettate" field_value='mediante "Origine Lead"' />
                        </b-col>
                        <b-col md="4" lg="4">
                          <showdata field_name="Accettazione termini e condizioni lead" field_value="Si" />
                        </b-col>
                        <b-col md="4" lg="4">
                          <showdata field_name="Accettazione informativa privacy lead" field_value="Si" />
                        </b-col>
                        <b-col md="4" lg="4">
                          <showdata field_name="Iscritto newsletter" :field_value="campiform.lead.newsletter" />
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-tab>

                  <b-tab>
                    <template #title>
                      <feather-icon
                        icon="ClipboardIcon"
                        size="16"
                        class="mr-0 mr-sm-50"
                      />
                      <span class="d-none d-sm-inline">Commenti <small v-if="n_commenti > 0">({{ n_commenti }})</small></span>
                    </template>
                    <b-card bg-variant="Default">
                      <b-row>
                        <b-col md="12" lg="6">
                          <div class="text-left">
                            <b-button
                              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                              variant="outline-primary"
                              class="mb-1"
                              @click="sidebarCommento('Nuovo Commento','','')"
                            >
                              <feather-icon icon="PlusIcon" /> Inserisci Commento
                            </b-button>
                          </div>

                          <div class="comments-list">
                            <vue-perfect-scrollbar
                              class="scroll-area"
                              :settings="perfectScrollbarSettings"
                            >
                            <ul class="list-unstyled">
                              <li v-for="riga in list_comments"
                                :key="riga.id"
                                class="d-flex justify-content-between align-items-center riga" 
                                @click="sidebarCommento('Modifica Commento',riga.id,riga.content)">
                                <div v-html="riga.content"></div>
                                <div class="text-muted dx">{{ riga.author }}<br /><span class="blockquote-footer">{{ formattazioneDataOra(riga.update_at, 'it') }}</span></div>
                              </li>
                            </ul>
                            
                            </vue-perfect-scrollbar>
                          </div>
                        </b-col>                      
                        <b-col md="12" lg="6">
                          <editnotelead :editnote_id="campiform.lead.id" :editnote_note="campiform.lead.note" editnote_dimensione_btn="piccolo" editnote_atc_aggiorna="si" @campoNoteAggiornato="updateCampoNote"></editnotelead>
                          <br />
          
                          <dl>
                            <dt class="text-muted">Note</dt>
                            <dd v-html="campiform.lead.note"></dd>
                          </dl>
                        </b-col>
                      </b-row>
                      
                    </b-card>
                  </b-tab>
                </b-tabs>
            </b-card-body>
        </b-card>
    </div>

    <b-sidebar
      ref="sidebar_azioni"
      id="sidebar-right"
      bg-variant="white"
      :title="infoSideBar.titolo"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          <div class="divider">
            <div class="divider-text text-primary fw-bolder mt-2">AZIONI</div>
          </div>
          
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="$router.push({ name: 'crm-leads-edit', params: { id_riga: infoSideBar.id }})"
          >
            <feather-icon icon="Edit2Icon" /> Modifica
          </b-button>
          
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-warning" block
            class="mb-1"
            @click="recuperaDatiSocietari(infoSideBar.id,infoSideBar.titolo,infoSideBar.piva)"
          >
            <feather-icon icon="RefreshCwIcon" /> Aggiorna Dati Societari
          </b-button>   
          
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger" block
            class="mb-1"
            @click="cancellaRiga(infoSideBar.id)"
          >
            <feather-icon icon="Trash2Icon" /> Cancella
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary" block
            class="mb-1"
            :href="`mailto:${infoSideBar.email}`"
          >
            <feather-icon icon="MailIcon" /> Invia Email
          </b-button>
          
          <newactivity field_tipo="Lead" :field_id="infoSideBar.id" :field_nome_relazione="infoSideBar.titolo"></newactivity>

          <div v-if="infoSideBar.check_duplicata">
            <b-alert variant="warning" class="mt-4" show>
              <h4 class="alert-heading">Questa email è già presente in un altro lead o in un cliente, pertanto NON può essere convertita in un nuovo cliente</h4>
            </b-alert>
          </div>
          <div v-else>            
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary" block
              class="mb-1"
              @click="conversioneTipoRiga(infoSideBar.id)"
            >
              <feather-icon icon="TrendingUpIcon" /> Controlla e Converti
            </b-button>
          </div>

        </div>

      </div>
    </b-sidebar>

    <b-modal
      ref="openapi-modal"
      size="lg"
      centered
      hide-footer
      title="Ricerca Dati Societari"
      @hide="resetOpenapimodal"
    >
      <b-tabs content-class="pt-1" fill>
        <b-tab active>
          <template #title>
            <span>per Partita Iva</span> 
            <b-badge variant="success" style="margin-left: 10px; background-color: #009688;">Consigliata</b-badge>
          </template>
          
          <div v-if="this.controllo_piva_presente === false">
            <b-form>
              <b-form-group label-for="ricerca_piva">
                <b-form-input id="basicInput" placeholder="Partita Iva" v-model="campiformOpenApiRicercaPerPiva.piva" />
              </b-form-group>

              <b-button
                variant="primary"
                type="submit"
                :disabled="action_press_openApiRicercaPerPiva"
                @click.prevent="openApiRicercaPerPiva()"
              >
                <div v-if="action_press_openApiRicercaPerPiva">Ricerca in corso <b-spinner small class="ml-1" /></div>
                <div v-else>Recupera Dati</div> 
              </b-button>
            </b-form>
          </div>
          <div v-else>
            <div v-if="bottone_rimandare_scheda_cliente">
              <p>La partita iva cercata è già associata al cliente <strong>{{ this.dati_controllo_piva.company_name }}</strong></p>
              <p>
                <b-button
                  variant="primary"
                  @click="$router.push({ name: 'crm-customers-show', params: { id_riga: dati_controllo_piva.userable_id }})"
                >
                  <feather-icon icon="EyeIcon" /> Visualizza Cliente
                </b-button>
              </p>
            </div>
            <div v-if="bottone_ripristino_scheda_cliente">
              <p>La partita iva cercata è già associata al cliente <strong>{{ this.dati_controllo_piva.company_name }}</strong></p>
              <p>
                <b-button
                  variant="primary"
                  @click="ripristinaClienteDaSoftDelete(dati_controllo_piva.userable_id)"
                >
                  <feather-icon icon="RefreshCcwIcon" /> Ripristina Cliente
                </b-button>
              </p>
            </div>
          </div>

        </b-tab>
        <b-tab>
          <template #title>
            <span>per Ragione Sociale</span>
          </template>

          <div v-if="this.controllo_piva_presente_due === false">
            <b-form>
              <b-form-group label-for="ricerca_rag_sociale">
                <b-form-input id="basicInput" placeholder="Ragione Sociale" v-model="campiformOpenApiRicercaRagioneSociale.rag_sociale" />
              </b-form-group>

              <div v-if="open_api_ricerca_items" class="my-2">
                <b-overlay :show="isLoadingRecuperoDati" rounded="sm" spinner-small spinner-variant="primary">
                  <b-table striped :items="open_api_ricerca_items" :fields="open_api_ricerca_fields">
                    <template #cell(denominazione)="data">
                      {{ data.item.denominazione }}
                    </template>
                    <template #cell(id)="data">
                      <b-button @click="pippo(data.item.id)" variant="outline-primary">Recupera dati</b-button>
                    </template>
                  </b-table>
                </b-overlay>
              </div>

              <b-button
                variant="primary"
                type="submit"
                :disabled="action_press_openApiRicercaPerRagioneSociale"
                @click.prevent="openApiRicercaPerRagioneSociale()"
              >
                <div v-if="action_press_openApiRicercaPerRagioneSociale">Ricerca in corso <b-spinner small class="ml-1" /></div>
                <div v-else>Recerca Azienda</div> 
              </b-button>
            </b-form>
          </div>
          <div v-else>
            <div v-if="bottone_rimandare_scheda_cliente_due">
              <p>La partita iva cercata è già associata al cliente <strong>{{ this.dati_controllo_piva_due.company_name }}</strong></p>
              <p>
                <b-button
                  variant="primary"
                  @click="$router.push({ name: 'crm-customers-show', params: { id_riga: dati_controllo_piva_due.userable_id }})"
                >
                  <feather-icon icon="EyeIcon" /> Visualizza Cliente
                </b-button>
              </p>
            </div>
            <div v-if="bottone_ripristino_scheda_cliente_due">
              <p>La partita iva cercata è già associata al cliente <strong>{{ this.dati_controllo_piva_due.company_name }}</strong></p>
              <p>
                <b-button
                  variant="primary"
                  @click="ripristinaClienteDaSoftDelete(dati_controllo_piva_due.userable_id)"
                >
                  <feather-icon icon="RefreshCcwIcon" /> Ripristina Cliente
                </b-button>
              </p>
            </div>
          </div>
          
        </b-tab>
      </b-tabs>
      
    </b-modal>

    <b-sidebar
      ref="sidebar_commenti"
      id="sidebar-commenti"
      bg-variant="white"
      :title="SideBarCommenti.tipo_operazione"
      :visible="sidebar_visibile"
      right
      backdrop
      shadow
    >
      <div>
        <div class="py-0 px-2">
          <validation-observer ref="simpleRules">
            <b-form>
              <b-form-group
                label="Commento"
                label-for="task-description"
              >
                <validation-provider
                #default="{ errors }"
                name="Commento"
                rules="required"
                >
                  <quill-editor
                    id="quil-content"
                    v-model="campiform_commenti.content"
                    :options="editorOption"
                    class="border-bottom-0"
                    style="height: 250px;"
                  />
                  <div
                    id="quill-toolbar"
                    class="d-flex justify-content-end border-top-0"
                  >
                    <!-- Add a bold button -->
                    <button class="ql-bold" />
                    <button class="ql-italic" />
                    <button class="ql-underline" />
                  </div>
                <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="mt-2">
                <b-button
                  variant="outline-primary"
                  type="submit"
                  @click.prevent="validationFormCommento()"
                  >
                  Salva
                </b-button>
                <b-button
                  v-if="SideBarCommenti.tipo_operazione == 'Modifica Commento'"
                  variant="outline-danger" 
                  class="ml-2"
                  type="submit"
                  @click.prevent="CancellaCommento(SideBarCommenti.id_commento)"
                  >
                  Cancella
                </b-button>
              </div>
            </b-form>
          </validation-observer>

        </div>
      </div>
    </b-sidebar>
      
  </div>
</template>

<script>
import router from '@/router'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import { BTabs, BTab, BRow, BCol, BAlert, BCard, BCardTitle, BCardHeader, BCardBody, BCardText, BSpinner, BFormGroup, BForm, BFormInput, BButton, BSidebar, VBToggle, BBadge, BFormTextarea, BTable, BMediaBody, BMedia, BMediaAside, BAvatar, BOverlay, VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import showdata from '@core/components/showdata/showdata.vue'
import moment from 'moment'
import { useRouter } from '@core/utils/utils'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required
} from '@validations'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import newactivity from '@core/components/newactivity/newactivity.vue'
import editnotelead from '@core/components/editnotelead/editnotelead.vue'

export default {
  components: {
    BTabs,
    BTab,
    BRow,
    BCol,
    BAlert, 
    BCard, 
    BCardTitle, 
    BCardHeader, 
    BCardBody, 
    BCardText, 
    BSpinner,
    BFormGroup,
    BForm, 
    BFormInput, 
    BButton,
    BSidebar, 
    VBToggle, 
    BBadge,
    BFormTextarea, 
    BTable, 
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar, 
    BOverlay, 

    showdata,
    VuePerfectScrollbar,
    ValidationProvider,
    ValidationObserver,
    localize,
    it,
    quillEditor,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent, 

    newactivity,
    editnotelead,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    /*
    const { route, router } = useRouter()

    // Route Params
    const routeParams = computed(() => route.value.params)
    watch(routeParams, () => {
      // eslint-disable-next-line no-use-before-define
      console.log("siamo qui ---------------------")
      console.log(routeParams)

      this.campiform.lead.name = 'Test name';
    })
    */

  },
  directives: {
    'b-toggle': VBToggle, 
    'b-tooltip': VBTooltip, 
    Ripple,
  },
  data() {
    return {
      perfectScrollbarSettings: '',
      editorOption: {
        modules: {
          toolbar: '#quill-toolbar',
        },
        placeholder: 'scrivi commento ...',
      },
      id_italina: '1',
      campiform: {
        lead: {
          id: '',
          email: '', 
          name: '', 
          surname: '',
          mobile_phone: '',
          phone: '', 
          reseller_id: '',
          reseller_name: '',
          assistant_id: '',
          assistant_name: '',
          evaluation_id: '',
          evaluation_name: '',
          rating: '',
          created_at: '',
          status: [],
          source: [],
          paument: [],
          website: '',
          note: '',
          section: '',
          path: ''
        },
        billing: {
          country_id: '',
          country_name: '',
          province_id: '',
          province_name: '',
          province_foreign: '', 
          address: '',
          zip: '',
          city: '',
          company_name: '',
          vat_number: '',
          fiscal_code: '',
          pec: '',
          dest_code: '',
          vat_scheme_id: null,
          vat_scheme_name: '',
          note_xml: '', 
          split_payment: ''
        },
        openapi: {
          scaricato: false,
        },
      },
      list_comments: { },
      n_commenti: 0,
      campiform_commenti: {
        content: '',
        id_commento: '',
        tipo_operazione: 'new'
      },
      campiform_commenti_start: {
        content: '',
        tipo_operazione: 'new'
      },
      sidebar_visibile: false,
      options_usercrm: [ ],
      options_evalution: [ ],
      options: [ ],
      options_province: [ ],
      options_regine_iva: [ ],
      area_select_provincia: true,
      Caricato: false,
      errore_caricamento: false,
      infoSideBar: {
        id: '',
        fullName: '',
      },
      SideBarCommenti: { 
        tipo_operazione: '', 
        id_card: '', 
        id_commento: '' 
      },
      required,
      controllo_duplicato_show: true,
      controllo_duplicato: {
        controllo_lead: '',
        controllo_user: '',
        controllo_billing: '',
        id: '', 
      },
      
      campiformOpenApiRicercaPerPiva: { 
        piva: '',
        id_riga: '',
        userable_type: 'Lead', //fisso per la sezione LEAD del fronend vue (serve solo all'api per lavoro e non fare casino nelle operazioni richieste)
      },
      action_press_openApiRicercaPerPiva: false,
      campiformOpenApiRicercaRagioneSociale: { 
        rag_sociale: '',
        id_riga: '',
        userable_type: 'Lead', //fisso per la sezione LEAD del fronend vue (serve solo all'api per lavoro e non fare casino nelle operazioni richieste)
      },
      action_press_openApiRicercaPerRagioneSociale: false,

      isLoadingRecuperoDati: false,
      open_api_ricerca_fields: ['denominazione', 'id'],
      open_api_ricerca_items: '',

      controllo_piva_presente: false,
      bottone_rimandare_scheda_cliente: false,
      bottone_ripristino_scheda_cliente: false,
      dati_controllo_piva: {},
      controllo_piva_presente_due: false,
      bottone_rimandare_scheda_cliente_due: false,
      bottone_ripristino_scheda_cliente_due: false,
      dati_controllo_piva_due: {}
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        3 : 'light-warning',
        2 : 'light-success',
        1 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    //caricamento asincrono
    this.controlloDuplicato();

    this.caricamentoDatiLead(router.currentRoute.params.id_riga);
  },
  methods: {
    formattazioneArray(ArrayPassato) {
      return ArrayPassato.map(obj => obj.name).join(', ');
    },
    caricamentoDatiLead(id_lead){
      const countryPromise = this.$http.get('api/fs/country/list')
      const provincePromise = this.$http.get('api/fs/province/list')
      const userCrmPromise = this.$http.get('api/crm/user/list?columnFilters={"role.type":"employee" }&page=1&perPage=100&status=active')
      const evaluationPromise = this.$http.get('api/fs/lead/evaluation/list')
      const vatSchemePromise = this.$http.get('api/fs/employee/vat-scheme/list')
      const infoLeadPromise = this.$http.get('api/crm/lead/card/'+id_lead)
      const CommentsPromise = this.$http.get('api/crm/comments/lead/list/'+id_lead+'?sort[]={"field":"created_at","type":"desc"}&page=1&perPage=100')

      Promise.all([countryPromise, provincePromise, userCrmPromise, evaluationPromise, vatSchemePromise, infoLeadPromise, CommentsPromise]).then(response => {
        if(response[0].data.statusCode===200){
          //caricamento lista nazioni
          this.options = response[0].data.reply

          if(response[1].data.statusCode===200){
            //caricamento lista province italiane
            this.options_province = response[1].data.reply

            if(response[2].data.statusCode===200){
              //caricamento lista "user Crm" (sia come "reseller" sia come "assistent")
              this.options_usercrm = response[2].data.reply.data
                  
              if(response[3].data.statusCode===200){
                //caricamento lista evalutation
                this.options_evalution = response[3].data.reply

                if(response[4].data.statusCode===200){
                  //caricamento lista regime iva
                  this.options_regine_iva = response[4].data.reply  
                  
                  if(response[5].data.statusCode===200){
                      //dati lead
                      this.campiform.lead = response[5].data.reply.leadData

                      //nome reseller
                      if(this.campiform.lead.reseller_id !== null){
                        var id_reseller = this.campiform.lead.reseller_id
                        var reseller = this.options_usercrm.filter(function(elem){
                            if(elem.id == id_reseller){
                            return elem.name
                            }
                        })

                        this.campiform.lead.reseller_name = reseller[0].name+' '+reseller[0].surname
                      } else {
                        this.campiform.lead.reseller_name = 'Da associare';
                      }

                      //nome assistent                    
                      if(this.campiform.lead.assistant_id !== null){
                        var id_assistant = this.campiform.lead.assistant_id
                        var assistent = this.options_usercrm.filter(function(elem){
                            if(elem.id == id_assistant){
                            return elem.name
                            }
                        })
                        this.campiform.lead.assistant_name = assistent[0].name+' '+assistent[0].surname
                      } else {
                        this.campiform.lead.assistant_name = 'Da associare';
                      }

                      //nome evalution
                      var id_evalution = this.campiform.lead.evaluation_id
                      var evalution = this.options_evalution.filter(function(elem){
                          if(elem.value == id_evalution){
                          return elem.text
                          }
                      })
                      this.campiform.lead.evalution_name = evalution[0].text
                      
                      if(response[6].data.statusCode===200){
                        //caricamento comment
                        this.list_comments = response[6].data.reply.data
                        this.n_commenti = response[6].data.reply.meta.total

                        //estrazione billing (sicrono)
                        if(this.campiform.lead.has_billing === true){
                          this.$http.get('api/crm/billing/lead/card/'+this.campiform.lead.id).then(response_billing => {
                            if((response_billing.data.statusCode>=200) && (response_billing.data.statusCode<=299)){
                              //caricamento dati billing
                              this.campiform.billing = response_billing.data.reply.billingData

                              //nazione
                              var id_nazione = this.campiform.billing.country_id
                              var nazione = this.options.filter(function(elem){
                                  if(elem.value == id_nazione){
                                  return elem.text
                                  }
                              })
                              this.campiform.billing.country_name = nazione[0].text

                              //provincia
                              var id_provincia = this.campiform.billing.province_id
                              var provincia = this.options_province.filter(function(elem){
                                  if(elem.value == id_provincia){
                                    return elem.text
                                  }
                              })
                              this.campiform.billing.province_name = provincia[0].text

                              //regime iva
                              var id_regime_iva = this.campiform.billing.vat_scheme_id
                              var regime_iva = this.options_regine_iva.filter(function(elem){
                                  if(elem.value == id_regime_iva){
                                    return elem.text
                                  }
                              })
                              this.campiform.billing.vat_scheme_name = regime_iva[0].text

                              this.$http.get('api/crm/billing/financialstatements/'+this.campiform.billing.id).then(response_bilancio => {
                                if((response_bilancio.data.statusCode>=200) && (response_bilancio.data.statusCode<=299)){
                                  //caricamento dati billing
                                  this.campiform.openapi = response_bilancio.data.reply

                                  console.log(this.campiform.openapi)
                                  
                                  this.Caricato = true;
                                  this.errore_caricamento = false;
                                } else {
                                  //risposta negativa (errore sul server)
                                  
                                  this.Caricato = false;
                                  this.errore_caricamento = true;
                                }
                              }).catch(e => {
                                console.log(e);
                              });
                              
                            } else {
                              //risposta negativa (errore sul server)
                              
                              this.Caricato = false;
                              this.errore_caricamento = true;
                            }
                          }).catch(e => {
                            console.log(e);
                          });
                        } else {
                          //lead senza dati billing
                          this.Caricato = true;
                          this.errore_caricamento = false;
                        }
                      } else {
                      this.Caricato = false;
                      this.errore_caricamento = true;
                      }
                  } else {
                  this.Caricato = false;
                  this.errore_caricamento = true;
                  }
                } else {
                this.Caricato = false;
                this.errore_caricamento = true;
                }
              } else {
              this.Caricato = false;
              this.errore_caricamento = true;
              }

              
            } else {
              this.Caricato = false;
              this.errore_caricamento = true;
            }
          } else {
            this.Caricato = false;
            this.errore_caricamento = true;
          }
        } else {
          this.Caricato = false;
          this.errore_caricamento = true;
        }
      })
    },
    goTO(id_lead){
      //console.log('qui ------------------------- '+id_lead)
      //this.campiform.lead.name = "Stronzo";

      this.$router.replace('/crm/leads/show/'+id_lead)

      this.Caricato = false;
      this.controlloDuplicato();
      this.caricamentoDatiLead(id_lead);
    },
    sidebarActionShow(id, titolo, piva, email, controllo_duplicato_show, note) {
      let titolo_ok = '';
      if(titolo == '--- null'){
        titolo_ok+= 'Scheda Lead';
      } else {
        titolo_ok+= titolo
      }
      this.infoSideBar = { id: id, titolo: titolo_ok, piva: piva, email: email, check_duplicata: controllo_duplicato_show, note: note };

      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },
    cancellaRiga(id_riga){
      console.log("cancella Leads -> "+id_riga)

      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva del Leads?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/lead/delete/'+id_riga)
            .then(response => { 
                console.log("risposta server --------")
                console.log(response.data)

                if(response.data.statusCode===200){
                    //risposta positiva
                    this.$router.replace('/crm/leads')
                        .then(() => {
                            this.$swal({
                                icon: 'success',
                                title: response.data.reply.reply,
                                //text: 'operazione effettuata correttamente',
                                confirmButtonText: 'chiudi',
                                customClass: {
                                confirmButton: 'btn btn-success',
                                },
                            })
                        })
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    sidebarCommento(tipo_operazione, id_commento, testo_commento){
      this.SideBarCommenti = { tipo_operazione: tipo_operazione, id_card: router.currentRoute.params.id_riga, id_commento: id_commento };

      if( tipo_operazione == 'Nuovo Commento' ){
        this.campiform_commenti.tipo_operazione = 'new'
        this.campiform_commenti.content = ''
        this.campiform_commenti.id_commento = ''
      } else {
        this.campiform_commenti.tipo_operazione = 'edit'
        this.campiform_commenti.content = testo_commento
        this.campiform_commenti.id_commento = id_commento
      }

      this.$root.$emit('bv::toggle::collapse', 'sidebar-commenti')
    },
    validationFormCommento(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line

          //c'è da aggregare altre informazioni da inviare all'api per creare l'utente ???
          console.log(this.campiform_commenti)

          let url_api = '';
          if( this.campiform_commenti.tipo_operazione == 'new' ){
            url_api+='api/crm/comments/lead/card/'+router.currentRoute.params.id_riga
          } else {
            url_api+='api/crm/comments/lead/card/'+router.currentRoute.params.id_riga+'/'+this.campiform_commenti.id_commento;
          }

          this.$http.post(url_api, 
            this.campiform_commenti 
            ).then(response => {
              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                if(response.data.reply.commentData.id > 0){
                  //salvataggio effettuato + aggiorna lista commenti (ultimo salvato primo della lista)
                  if( this.campiform_commenti.tipo_operazione == 'new' ){
                    //aggiungi alla lista
                    this.list_comments.unshift(response.data.reply.commentData)
                    
                    //aggiorna conteggio commenti
                    //console.log("conta -> "+this.list_comments.length)
                    this.n_commenti = this.list_comments.length

                  } else {
                    //modifica -> aggiorna relativa voce della lista
                    this.list_comments = this.list_comments.map(object => {
                      if (object.id == response.data.reply.commentData.id) {
                        return {...object,
                          content: response.data.reply.commentData.content,
                          update_at: response.data.reply.commentData.update_at,
                          author: response.data.reply.commentData.author};
                      }
                      return object;
                    });
                  }

                  //chiudo finestra ...
                  this.$refs.sidebar_commenti.hide();
                  //azzero form commento
                  this.campiform_commenti = this.campiform_commenti_start

                  //reset validation form
                  this.$refs.simpleRules.reset();

                  this.$swal({
                      icon: 'success',
                      title: 'Nuovo commento salvato correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })
                } else {
                  //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore',
                      text: 'Salvataggio riuscito ma con errore '+response.data.statusCode+', ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }

              } else {
                //risposta negativa (errore sul server)
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                    customClass: {
                    confirmButton: 'btn btn-outline-primary',
                    },
                })
              }
            }).catch(e => {
              console.log(e);
            });

        }
      })
    },
    CancellaCommento(id_riga_commento){
      this.$swal({
        title: 'Sei sicuro di voler procedere?',
        text: "conferma la cancellazione definitiva del commento?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla operazione',
        customClass: {
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {

            this.$http.post('api/crm/comments/lead/delete/'+router.currentRoute.params.id_riga+'/'+id_riga_commento)
            .then(response => { 
                if(response.data.statusCode===200){
                  //risposta positiva
                  this.$swal({
                      icon: 'success',
                      title: response.data.reply.reply,
                      //text: 'operazione effettuata correttamente',
                      confirmButtonText: 'chiudi',
                      customClass: {
                      confirmButton: 'btn btn-success',
                      },
                  })

                  //chiudi sidebar
                  this.$refs.sidebar_commenti.hide();

                  //rimuovi il commento eliminato dalla lista
                  this.list_comments = this.list_comments.filter(riga => riga.id != id_riga_commento);

                  //aggiorna conteggio commenti
                  this.n_commenti = this.list_comments.length
                    
                } else {
                 //chiudi sidebar
                 this.$refs.sidebar_commenti.hide();

                 //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })
        }
      })
    },
    formattazioneDataOra(created_at,lang) {
      if (created_at) {
        return moment(String(created_at)).locale(lang).format('DD/MM/YYYY - HH:mm:ss')
      } else {
        return '---'
      }
    },
    controlloDuplicato(){
      //console.log("Controllo duplicato richiamata in maniera asincrona -> "+router.currentRoute.params.id_riga);

      this.$http.get('api/crm/lead/check_duplicate_lead/'+router.currentRoute.params.id_riga)
        .then(response => { 
            if(response.data.statusCode===200){
              this.controllo_duplicato_show = true;
              this.controllo_duplicato = response.data.reply.data;

              if(
                (this.controllo_duplicato.stato_lead == 'Ok') && 
                (this.controllo_duplicato.controllo_lead == 'Ok') && 
                (
                  (this.controllo_duplicato.controllo_clienti == 'Ok') || 
                  (this.controllo_duplicato.controllo_clienti == 'Billing non trovato.')
                )
              ){
                //tutto ok -> non visualizzo gli status
                this.controllo_duplicato_show = false;
              } else {
                //almeno un alert c'è
                this.controllo_duplicato_show = true;
              }

              //console.log(this.controllo_duplicato)
                
            } else {
              //risposta negativa (errore sul server)
              this.controllo_duplicato_show = false;
              
            }
        }).catch(e => {
            console.log(e);
        })

    },

    conversioneTipoRiga(id_riga){
      this.$swal({
        title: 'Sei sicuro di voler procedere alla conversione in cliente?',
        //text: 'Sarà possibile modificare i dati del lead prima di convertirlo in cliente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'SI, Procedi',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          //lancio chiamata api per controllare che ci siamo tutti i dati per la conversione del lead in cliente
          this.$http.get('api/crm/lead/check_convert_lead/'+id_riga)
            .then(response => { 
                console.log("risposta server --------")
                console.log(response.data)

                if(response.data.statusCode===200){
                  //risposta positiva
                  if((response.data.reply.data.controllo_lead != 'Ok') || (response.data.reply.data.controllo_billing != 'Ok')){
                    //mancano dei dati necessari per con conversione diretta del lead in cliente
                    console.log("risposta\n"+response.data.reply.data.controllo_lead+"\n"+response.data.reply.data.controllo_billing)

                    let messaggio = '';
                    if(response.data.reply.data.controllo_lead == 'Ok'){
                      messaggio+= response.data.reply.data.controllo_billing+'\n\n'+response.data.reply.data.campi_mancanti_billing;
                    } else {
                      messaggio+= response.data.reply.data.controllo_lead+'\n\n'+response.data.reply.data.campi_mancanti_lead;
                    }

                    console.log("messaggio -> "+messaggio);

                    this.$swal({
                      title: 'Non è possibile convertire il lead direttamente in cliente',
                      text: messaggio,
                      icon: 'error',
                      showCancelButton: true,
                      confirmButtonText: 'Aggiorna e Converti',
                      cancelButtonText: 'Annulla',
                      customClass: {
                        confirmButton: 'btn btn-primary',
                        cancelButton: 'btn btn-outline-primary ml-1',
                      },
                      buttonsStyling: false,
                    }).then(result => {
                      if (result.value) {
                        //modifica lead e poi converti (stessa procedura della versione precedente - versione iniziale)
                        this.$router.replace('/crm/leads/edit_convert/'+id_riga)
                      }
                    })

                  } else {
                    //ci sono tutti i dati per la conversione diretta del lead in cliente
                    console.log("ci sono tutti i dati per la conversione diretta del lead in cliente !!!");

                    this.$http.post('api/crm/customer/convert/'+id_riga).then(response_convert => {
                      if((response_convert.data.statusCode>=200) && (response_convert.data.statusCode<=299)){
                        //risposta positiva => conversione avvenuta
                        if(response_convert.data.reply.messaggio != 'Controllo P.Iva Ok'){
                          //risposta negativa (P.IVA già associata ad un altro cliente !!!)
                          this.$swal({
                              icon: 'warning',
                              //title: 'Si è verificato un errore nella conversione diretta da lead a cliente',
                              title: response_convert.data.reply.message,
                              //text: response_convert.data.reply.message,
                              customClass: {
                              confirmButton: 'btn btn-outline-warning',
                              },
                          })
                            
                          this.action_press = false
                        } else {
                          //conversione andata a buon fine
                          this.$router.replace('/crm/customers/show/'+response_convert.data.reply.customer[0].id)
                          .then(() => {
                              this.$swal({
                                  icon: 'success',
                                  title: 'Nuovo cliente creato correttamente',
                                  confirmButtonText: 'chiudi',
                                  customClass: {
                                  confirmButton: 'btn btn-success',
                                  },
                              })
                          })

                        }
                      
                      } else {
                        //risposta negativa (errore sul server)
                        this.$swal({
                            icon: 'error',
                            title: 'Si è verificato un errore nella conversione diretta da lead a cliente',
                            text: response_convert.data.reply.message,
                            customClass: {
                            confirmButton: 'btn btn-outline-primary',
                            },
                        })
                          
                        this.action_press = false
                      }
                    }).catch(e => {
                      console.log(e);
                    });

                  }
                    
                } else {
                //risposta negativa (errore sul server)
                  this.$swal({
                      icon: 'error',
                      title: 'Si è verificato un errore',
                      text: 'operazione non riuscita, ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }
            }).catch(e => {
                console.log(e);
            })       
        }
      })
    },

    recuperaDatiSocietari(id_riga,rag_sociale,piva){
      this.openapiModal = {
        id_riga: id_riga,
        rag_sociale: rag_sociale,
        piva: piva,
      }

      console.log("this.openapiModal")
      console.log(this.openapiModal)

      this.campiformOpenApiRicercaPerPiva.id_riga = id_riga;

      if((piva !== null) && (piva !== undefined) && (piva != '')){
        //auto completo il form per "partita iva" di ricerca sulla modale
        this.campiformOpenApiRicercaPerPiva.piva = piva;
      }

      this.campiformOpenApiRicercaRagioneSociale.id_riga = id_riga;

      if((rag_sociale !== null) && (rag_sociale !== undefined) && (rag_sociale != '')){
        //auto completo il form per "ragione sociale" di ricerca sulla modale
        this.campiformOpenApiRicercaRagioneSociale.rag_sociale = rag_sociale;
      }

      this.$refs['openapi-modal'].show()
    },
    resetOpenapimodal(){
      this.openapiModal = {
        id_riga: '',
        rag_sociale: '',
        piva: '',
      }

      //reset tutte i form della modale
      this.resetCampiRicercaOpenApiTutti();
    },
    
    openApiRicercaPerPiva(){
      //console.log('Si parte - openApiRicercaPerPiva()')
      //console.log(this.campiformOpenApiRicercaPerPiva)

      this.action_press_openApiRicercaPerPiva = true

      this.$http.post('api/crm/customer/openapi/ricerca/recuperadati', 
        this.campiformOpenApiRicercaPerPiva 
        ).then(response => { 
          console.log("risposta server --------")
          console.log(response.data)

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva dal server
            this.action_press_openApiRicercaPerPiva = false;

            if(response.data.reply.controllo_piva_presente === true){
              //Risposta dal controllo della P.Iva già presente su un cliente
              this.controllo_piva_presente = true;
              this.dati_controllo_piva = response.data.reply;

              if(response.data.reply.deleted_at	=== null){
                //solo bottone per rimandare a scheda cliente
                this.bottone_rimandare_scheda_cliente = true;
                this.bottone_ripristino_scheda_cliente = false;
              } else {
                //bottone per ripristino cliente e poi rimandare a scheda cliente
                this.bottone_rimandare_scheda_cliente = false;
                this.bottone_ripristino_scheda_cliente = true;
              }

            } else {
              //Risposta alle operazioni di recupero dati da OpenApi

              //esito negativo da OpenApi
              if(response.data.reply.status == 'errore'){
                //notifica errore
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: response.data.reply.messaggio,
                    customClass: {
                    confirmButton: 'btn btn-danger',
                    },
                })

              } else {
                //esito ok da OpenApi
                this.$swal({
                    icon: 'success',
                    title: 'Dati sociatari e bilanci aggiornati',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                })

                //ricarico i dati
                this.caricamentoDatiLead(this.campiformOpenApiRicercaPerPiva.id_riga);

              }

            }
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.action_press_openApiRicercaPerPiva = false;
          }
        }).catch(e => {
          console.log(e);
        });

    },
    ripristinaClienteDaSoftDelete(userable_id){
      console.log("ripristinaClienteDaSoftDelete -> " + userable_id);

      this.$http.post('api/crm/customer/ricovery/' + userable_id).then(response => { 
          console.log("risposta server 'ricovery' --------")
          //console.log(response.data)

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            
            //redirect con messaggio specifico
            this.$router.replace('/crm/customers/show/' + userable_id)
            .then(() => {
              this.$swal({
                  icon: 'success',
                  title: 'Cliente ripristinato correttamente',
                  confirmButtonText: 'chiudi',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
              })
            })
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.action_press_openApiRicercaPerRagioneSociale = false;
          }
        }).catch(e => {
          console.log(e);
        });
    },
    openApiRicercaPerRagioneSociale(){
      //console.log('Si parte - openApiRicercaPerRagioneSociale()')
      //console.log(this.campiformOpenApiRicercaRagioneSociale)

      this.action_press_openApiRicercaPerRagioneSociale = true

      this.$http.post('api/crm/customer/openapi/ricerca/ragionisociali', 
        this.campiformOpenApiRicercaRagioneSociale 
        ).then(response => { 
          //console.log("risposta server --------")
          //console.log(response.data)

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            this.action_press_openApiRicercaPerRagioneSociale = false;

            console.log(response.data.reply.dati.data)
            this.open_api_ricerca_items = response.data.reply.dati.data;
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.action_press_openApiRicercaPerRagioneSociale = false;
          }
        }).catch(e => {
          console.log(e);
        });
    },
    resetCampiRicercaOpenApiTutti(){
      this.campiformOpenApiRicercaPerPiva = {
        piva: '',
        id_riga: '',
      }
      this.campiformOpenApiRicercaRagioneSociale = {
        rag_sociale: '',
        id_riga: '',
      }

      //eventuale ricerca ragioni sociali
      this.open_api_ricerca_items = '';
    },
    pippo(id) {
      // Implementa la tua funzione qui
      console.log('ID:', id);
      console.log(this.campiformOpenApiRicercaRagioneSociale)

      this.isLoadingRecuperoDati = true;

      this.$http.post('api/crm/customer/openapi/ricerca/recuperadati', 
          { 
            piva: id, //codice openapi per il recupero dei dati dell'azienda
            id_riga: this.campiformOpenApiRicercaRagioneSociale.id_riga,
            userable_type: 'Lead',
          }
        ).then(response => { 
          console.log("risposta server --------")
          console.log(response.data)

          /*
          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva
            this.isLoadingRecuperoDati = false;

            //esito negativo da OpenApi
            if(response.data.reply.status == 'errore'){
              //notifica errore
              this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore',
                  text: response.data.reply.messaggio,
                  customClass: {
                  confirmButton: 'btn btn-danger',
                  },
              })

            } else {
              //esito ok da OpenApi
              this.$router.replace('/crm/leads/show/'+this.campiformOpenApiRicercaRagioneSociale.id_riga)
              .then(() => {
                this.$swal({
                    icon: 'success',
                    title: 'Dati sociatari e bilanci aggiornati',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                })
              })

            }
            
          }
          */

          if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
            //risposta positiva dal server
            this.isLoadingRecuperoDati = false;

            if(response.data.reply.controllo_piva_presente === true){
              //Risposta dal controllo della P.Iva già presente su un cliente
              this.controllo_piva_presente_due = true;
              this.dati_controllo_piva_due = response.data.reply;

              if(response.data.reply.deleted_at	=== null){
                //solo bottone per rimandare a scheda cliente
                this.bottone_rimandare_scheda_cliente_due = true;
                this.bottone_ripristino_scheda_cliente_due = false;
              } else {
                //bottone per ripristino cliente e poi rimandare a scheda cliente
                this.bottone_rimandare_scheda_cliente_due = false;
                this.bottone_ripristino_scheda_cliente_due = true;
              }

            } else {
              //Risposta alle operazioni di recupero dati da OpenApi

              //esito negativo da OpenApi
              if(response.data.reply.status == 'errore'){
                //notifica errore
                this.$swal({
                    icon: 'error',
                    title: 'Si è verificato un errore',
                    text: response.data.reply.messaggio,
                    customClass: {
                    confirmButton: 'btn btn-danger',
                    },
                })

              } else {
                //esito ok da OpenApi
                this.$swal({
                    icon: 'success',
                    title: 'Dati sociatari e bilanci aggiornati',
                    confirmButtonText: 'chiudi',
                    customClass: {
                    confirmButton: 'btn btn-success',
                    },
                })

                //ricarico i dati
                this.caricamentoDatiLead(this.campiformOpenApiRicercaPerPiva.id_riga);

              }

            }
            
          } else {
            //risposta negativa (errore sul server)
            this.$swal({
                icon: 'error',
                title: 'Si è verificato un errore',
                text: response.data.reply.message,
                customClass: {
                confirmButton: 'btn btn-outline-primary',
                },
            })

            this.isLoadingRecuperoDati = false;
          }




        }).catch(e => {
          console.log(e);
        });

    },

    
    updateCampoNote(nuovoValore){
      this.campiform.lead.note = nuovoValore;
    }

  },
}
</script>

<style lang="scss">  
  .mio_btn_float{
    display: block;
    position: absolute;
    top: -55px;
    right: 2px;
    z-index: 10;
  }

  .capitalize-first-word::first-letter {
    text-transform: uppercase;
  }
  
  .transaction-title {
    margin-bottom: .2rem;
    margin-top: .2rem;
  }
  .comments-list .scroll-area {
    position: relative;
    margin: auto;
    padding: 10px;
    width: 100%;
    height: 450px;
    border: 1px solid #eee;
  }
  .comments-list .scroll-area ul li.riga{
    border-bottom: 1px solid #eee;
    padding: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 0.2s, border-color 0s;
    position: relative;
  }
  .comments-list .scroll-area ul li.riga:hover{
    transform: translateY(-4px);
    box-shadow: 0 3px 10px 0 #ebe9f1;
  }
  .comments-list .scroll-area ul li.riga:last-child{
    border-bottom: none;
  }
  .comments-list .scroll-area ul li.riga .dx{
    min-width: 150px;
    margin-left: 25px;
    text-align: right;
  }

  .logs-colonna{
    background: #fafafa;
    padding: .4rem .8rem;
    font-size: 0.85rem;
    color: #d81b60;
    word-break: break-word;
  }
</style>